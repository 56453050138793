
import { Options, Vue } from "vue-property-decorator";
import Canjiandanwei from "./canjiandanwei.vue";
import Fuzeren from "./fuzeren.vue";
import Quyutype from "./quyutype.vue";
import * as api from "@/api/anquanxunjian";

@Options({
  components: {
    Canjiandanwei,
    Fuzeren,
    Quyutype,
  },
})
export default class extends Vue {
  companyName = "";
  changePerson = "";
  areaType = "";
  areaColumns = [];
  form = {
    name: "",
    // projectId: '',
    companyId: "",
    changePersonId: "",
    typeId: "",
  };
  get projectId() {
    return this.$store.state.project.projectinfo.projectId;
  }
  fShowCanjianPopover(type: string) {
    (this as any).$refs[type].showPopup = true;
  }
  mounted() {
    this.fGetAreaList();
  }
  fGetAreaList() {
    api
      .projectArealist({
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res: any) => {
        if (res.code !== 0) {
          this.areaColumns = res.data;
        }
      });
  }

  fSumbit() {
    (this as any).$refs.addArea.validate().then(() => {
      let arr = this.areaColumns.filter((item: any) => {
        return item.name == this.form.name;
      });
      if (arr.length > 0) {
        this.$toast.success({
          duration: 1500,
          message: "该区域已经创建，请从列表中选择",
          forbidClick: true,
        });
      } else {
        var pid = this.$store.state.project.projectinfo.projectId;
        api
          .projectAreaSave(
            {
              ...this.form,
              projectId: pid,
            },
            pid
          )
          .then((res: any) => {
            // this.initareaIds()
            if (res.code === 0) {
              this.$toast.success({
                message: res.msg,
                forbidClick: true,
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            } else {
              this.$toast.fail(res.msg);
            }
          });
      }
    });
  }
}
